import React from "react"
import Articles from "./articles"

const NewsFeed = () => {

    return (
      
    <Articles /> 
      
    )
}

export default NewsFeed
