import React, { useEffect, useState } from "react"
import Article from "./article"
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { List, ListItem } from "@mui/material"
import { useInView } from 'react-intersection-observer';

const query = graphql`
  query stories {
    allNews(sort: { fields: time_published, order: DESC }) {
      nodes {
        title
        url
        time_published
        authors
        summary
        banner_image
        source
        category_within_source
        source_domain
      }
    }
  }
`

const Articles = () => {


  const data = useStaticQuery(query)
  const articles = data.allNews.nodes.map((story) => {
    return { ...story, key: uuidv4() }
  })
  const initArticlesList = articles.map((article, index) => {

    return (
      <ListItem  key={uuidv4()} id={"article-"+index} sx={ index > 5 ?{display: "none"}:{}}>
        <Article {...article} />
      </ListItem>
  )});



  const [lastVisible, setLastVisible] = useState(5)
  const [articlesList, setArticlesList] = useState(initArticlesList)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100px"
  });


  useEffect( () => {

    if (entry?.isIntersecting){
      setLastVisible(lastVisible + 2)
    }
  },[inView])
  



  const lastVisibleDivider = (
    <ListItem key={"last-visible-" + uuidv4()} ref={ref}></ListItem>
  )

  

  useEffect(() => {
    const articlesList = articles.map((article, index) => {

      return (
        <ListItem  key={uuidv4()} id={"article-"+index} sx={ index > lastVisible ?{display: "none"}:{}}>
          <Article {...article} />
        </ListItem>
    )});

    articlesList.splice(lastVisible+1, 0, lastVisibleDivider)
    setArticlesList(articlesList)
  }, [lastVisible])
  
  return (
    <>
    <List>
      {articlesList}
    </List>
    </>
  )
}


export default Articles
