import React from 'react';
import Layout from "../components/layout"
import NewsFeed from '../components/newsfeed';

const IndexPage = () => (
    <Layout>
      <NewsFeed />
    </Layout>
)

export default IndexPage
