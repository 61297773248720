import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material"
import * as React from "react"

const getRelativeTime = date => {
  const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minutes: 60 * 1000,
    seconds: 1000,
  }

  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
    style: "narrow",
  })

  const elapsed = date - Date.now()
  // "Math.abs" accounts for both "past" & "future" scenarios
  for (let u in units)
    if (Math.abs(elapsed) > units[u] || u === "seconds") {
      const relativeTime = Math.round(elapsed / units[u])
      return rtf.format(relativeTime, u)
    }
}

const Article = props => {
  const title = props.title
  const subheader = `${props.source_domain} | ${getRelativeTime(
    new Date(props.time_published)
  )}`
  return (
    <Card className="my-2  font-serif">
      <CardActionArea href={props.url} target="_blank">
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={{ className: "text-lg font-semibold" }}
          subheaderTypographyProps={{ className: "text-sm" }}
        />
        <CardContent className="flex flex-col md:flex-row text-base">
          <Box className="min-w-full w-1/3 md:min-w-0 p-2">
            <CardMedia component="img" image={props.banner_image} />
          </Box>
          <Box className="p-2">
            <Typography>{props.summary}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default Article
